import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { Icon, Text } from "@atoms";
import { m } from "framer-motion";
import { usePlayerContext, Player, Audio } from "@vime/react";
import { useAppState } from "@state";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const AudioPlayer = ({ audio: url, transcript, color }) => {
  const [progress, setProgress] = useState(0);
  const [, dispatch] = useAppState();
  const ref = useRef();

  const lang = useLang();

  const [currentTime] = usePlayerContext(ref, "currentTime", 0);
  const [duration] = usePlayerContext(ref, "duration", -1);
  // const [playing] = usePlayerContext(ref, "playing", -1);
  // const [isPlaybackReady] = usePlayerContext(ref, "playbackReady", false);
  const [paused, setPaused] = usePlayerContext(ref, "paused", true);

  const openTranscript = () => {
    dispatch({ type: "openTranscript", ...transcript });
  };

  useEffect(() => {
    setProgress(currentTime / duration);
  }, [currentTime]);

  const trackColor = ["bg-white", "bg-black"];
  const progressColor = ["bg-viridian", "bg-coral"];

  return (
    <div
      className={
        classNames()
        // "relative bottom-0 left-0 right-0 z-[950] bg-black p-3 text-white transition duration-200",
      }
    >
      <div className="h-0 w-0">
        <Player ref={ref}>
          {url && (
            <Audio>
              <source data-src={url} type="audio/mp4" />
              {/* <source> and <track> elements are placed here. */}
            </Audio>
          )}
        </Player>
      </div>
      <div className="flex w-full items-end gap-3">
        <button
          className="relative h-20 w-20 shrink-0 rounded-full border-2 border-current p-3"
          type="button"
          onClick={() => setPaused(!paused)}
        >
          <span className="sr-only">{paused ? "pause" : "play"}</span>
          <Icon
            name="play"
            fitHeight
            className={classNames(
              "absolute inset-0 p-2 transition duration-100",
              {
                "opacity-0": !paused,
              }
            )}
          />
          <Icon
            name="pause"
            fitHeight
            className={classNames(
              "absolute inset-0 p-3 transition duration-100",
              {
                "opacity-0": paused,
              }
            )}
          />
        </button>
        <div className="flex w-full flex-grow flex-col items-start justify-start gap-3">
          <div className={classNames("relative h-1 w-full", trackColor[color])}>
            {/* <p>{title}</p> */}
            <m.div
              className={classNames(
                "absolute bottom-0 left-0 top-0 w-full origin-left",
                progressColor[color]
              )}
              animate={{ scaleX: progress }}
            />
          </div>
          <button
            onClick={openTranscript}
            type="button"
            className="shrink-0 text-left"
          >
            <Text
              variant="sm"
              className="block w-max border-b border-viridian pb-1 text-justify font-bold uppercase"
            >
              {t("Read the transcript", lang)}
            </Text>
          </button>
        </div>

        {/* <div className="text-xs tabular-nums">
          {`${(currentTime / 60).toFixed(0)}:${(currentTime % 60)
            .toFixed(0)
            .padStart(2, 0)}`}
          /
          {`${(duration / 60).toFixed(0)}:${(duration % 60)
            .toFixed(0)
            .padStart(2, 0)}`}
        </div> */}
      </div>
    </div>
  );
};

export default AudioPlayer;
